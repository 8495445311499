import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WilayaViewService {

  private wilayaSubject = new BehaviorSubject<string>(this.getSavedWilaya() || 'All');
  wilaya$ = this.wilayaSubject.asObservable();

  setWilaya(wilaya: string) {
    localStorage.setItem('wilaya', wilaya); // Persist to localStorage
    this.wilayaSubject.next(wilaya); // Notify observers of the new Wilaya
  }

  getSavedWilaya(): string | null {
    return localStorage.getItem('wilaya');
  }

  removeSavedWilaya() {
    localStorage.removeItem('wilaya');
  }

  isWilayaSet(): boolean {
    return !!this.getSavedWilaya();
  }
}
